import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { sliderImages } from "../constants/palette";
import "./Slider.css";
import { AppStore } from "../constants/Stores";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import Cart from "../components/Cart";
import CareModal from "../components/CareModal";
import { Toaster } from "react-hot-toast";

const Shop = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = AppStore((state) => state.isMobile);
  const isCareOpen = AppStore((state) => state.isCareOpen);
  const toggleCare = AppStore((state) => state.toggleCare);
  const currentPath = location.pathname?.replace("/", "");
  const scrollToView = AppStore((state) => state.scrollToView);
  const addCartCount = AppStore((state) => state.addCartCount);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isShop, setIsShop] = useState(currentPath === "shop" ? true : false);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentIndex((prevIndex) => (prevIndex + 1) % sliderImages.length);
  //   }, 8000);

  //   return () => clearInterval(interval);
  // }, []);


  const changeImage = (index) => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % sliderImages.length);
    return;
  };

  return (
    <section
      className={`min-w-full md:h-[calc(100vh_-_85px)] h-full flex max-sm:flex-col gap-4 p-3 md:!flex-row md:overflow-x-scroll  ${
        currentPath === "shop" ? (isMobile ? "mt-[60px]" : "mt-[65px]") : ""
      }`}
      id="shop"
    >
      <Toaster
        containerStyle={{
          top: 0,
          right: 0,
        }}
        containerClassName="toast-notif"
        toastOptions={{
          className: "",
          style: {
            // position: "absolute",
            right: "0 !important",
            bottom: "100px !important",
            border: "1px solid #713200",
            // marginBottom: 50,
            // paddingBottom: 200,
            padding: "16px",
            color: "#713200",
          },
        }}
      />
      {currentPath == "shop" ? <Cart /> : null}
      <Navbar />
      <CareModal isOpen={isCareOpen} setIsOpen={toggleCare} />
      <AnimatePresence>
        <div className="slidershow w-full h-full min-h-[490px] rounded-[49px]  bg-black relative overflow-hidden border-2 border-primary-red" key={'slider-wrap'}>
          <div className="slider-container ">
              {currentPath == "shop" && !isMobile ? (
                <>
                  {sliderImages.map(
                    (image, index) =>
                      currentIndex === index && (
                        <motion.div
                          key={`${image.id}-desktop`}
                          className="slider-background hover:cursor-pointer"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 1 }}
                          style={{ backgroundImage: `url(${image.src})` }}
                          onClick={() => changeImage(index + 1)}
                        />
                      )
                  )}
                </>
              ) : (
                <motion.div
                key={`img-${sliderImages[0].id}`}
                  className="slider-background"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                  style={{ backgroundImage: `url(${sliderImages[0].src})` }}
                />
              )}
            {currentPath == "shop" && !isMobile ? (
              <div className="dots">
                {sliderImages.map((_, index) => (
                  <div
                  key={`dot-${index}`}
                    className={`dot ${currentIndex === index ? "active" : ""}`}
                    onClick={() => changeImage(index)}
                  />
                ))}
              </div>
            ) : null}
            {!isShop && (
              <div
                className="absolute w-screen bottom-14 max-sm:bottom-7 left-0 right-0 flex justify-center"
                onClick={() => navigate("/shop")}
              >
                <button className="round-red-button ml-[-3.5%] max-w-[110px] max-h-[40px]">
                  Shop
                </button>
              </div>
            )}
          </div>
        </div>

        {isShop && (
          <>
            <motion.div
              className="shop w-[480px] max-sm:w-full max-sm:min-w-full rounded-[49px] max-sm:min-h-[65%] h-full py-6 gap-7 flex flex-col items-center border-2 border-red-500 z-[1] justify-around overflow-hidden"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3 }}
              key={'slider-wrap-2'}
            >
              <div className="shop_title h-[15%] flex items-center flex-col">
                <h3 className="text-primary-red font-bold text-2xl">
                  BEAN COMB
                </h3>
                <div className="shop_price my-4 mb-2 flex items-center">
                  <h3 className="text-primary-red font-bold text-2xl">$40</h3>
                </div>
              </div>

              <p className="w-[70%] text-primary-red lg:text-xl font-light text-center ">
                The Bean comb is a durable and portable pocket comb equipped
                with a bean shaped hole to attach to a key chain.
              </p>
              <div className="shop_description w-full flex flex-col items-center gap-4 justify-evenly">
                <div
                  className="round-red-button max-w-[65%] md:flex hover:cursor-pointer"
                  onClick={addCartCount}
                >
                  ADD TO CART
                </div>
                <div className="round-white-button md:flex max-sm:mt-5 max-w-[65%]">
                  Size: L3" x W1"
                </div>
                <div className="round-white-button md:flex max-sm:mt-3 max-w-[65%]">
                  Made in Switzerland
                </div>
                <div className="round-white-button md:flex max-sm:mt-3 max-w-[65%]">
                  Sold with case
                </div>
                {/* </div> */}
              </div>
              {/* <div
              className="round-red-button !max-w-fit px-6 mt-6"
              onClick={() => (setIsShop(false), scrollToView("shop"))}
            >
              ADD TO CART
            </div> */}
            </motion.div>
            {isMobile ? (
              <>
                <div className="w-full h-full min-h-[270px] rounded-[49px] bg-black relative overflow-hidden border-2 border-primary-red">
                  <motion.div
                    key={`${sliderImages[1].id}-mobile`}
                    className="slider-background"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1 }}
                    style={{
                      backgroundImage: `url(${sliderImages[1].src})`,
                    }}
                  />
                </div>
                <div className="w-full h-full min-h-[270px] rounded-[49px] bg-black relative overflow-hidden border-2 border-primary-red">
                  <motion.div
                    key={`${sliderImages[2].id}-mobile`}
                    className="slider-background"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1 }}
                    style={{ backgroundImage: `url(${sliderImages[2].src})` }}
                  />
                </div>
                <div className="w-full h-full min-h-[270px] rounded-[49px] bg-black relative overflow-hidden border-2 border-primary-red">
                  <motion.div
                    key={`${sliderImages[3].id}-mobile`}
                    className="slider-background"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1 }}
                    style={{ backgroundImage: `url(${sliderImages[3].src})` }}
                  />
                </div>
              </>
            ) : null}
          </>
        )}

        <div className="bottom-0 max-sm:min-w-full z-[2]" key={'footer-wrap'}>
          <Footer />
        </div>
      </AnimatePresence>
    </section>
  );
};

export default Shop;
