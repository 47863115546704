import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { AppStore } from "../constants/Stores";
import Navbar from "../components/Navbar";
import { AnimatePresence, motion } from "framer-motion";
import {
  aboutVid,
  beanComb,
  mrAlex,
  mrsMelissa,
  sampleComb,
} from "../constants/palette";
import Menu from "../components/Menu";
import Cart from "../components/Cart";
import Footer from "../components/Footer";
import CareModal from "../components/CareModal";
import { Toaster } from "react-hot-toast";

const About = () => {
  const location = useLocation();
  const isCareOpen = AppStore((state) => state.isCareOpen);
  const toggleCare = AppStore((state) => state.toggleCare);
  const isMobile = AppStore((state) => state.isMobile);
  const currentPath = location.pathname?.replace("/", "");
  const layoutRef = useRef();

  useEffect(() => {
    const handleScroll = (event) => {
      if (layoutRef.current) {
        layoutRef.current.scrollLeft += event.deltaY;
      }
    };

    const layoutElement = layoutRef.current;
    layoutElement.addEventListener("wheel", handleScroll);

    return () => {
      layoutElement.removeEventListener("wheel", handleScroll);
    };
  }, []);

  return (
    <section
      className={`w-full h-[calc(100dvh_-_75px)] max-sm:h-full flex max-sm:flex-col gap-4 p-3 md:flex-row !overflow-hidden pb-6 md:pt-3 ${
        currentPath === "about" ? (isMobile ? "pt-[70px]" : "mt-[65px]") : ""
      }`}
      id="about"
      ref={layoutRef}
    >
      <Toaster
        containerStyle={{
          top: 0,
          right: 0,
        }}
        containerClassName="toast-notif"
        toastOptions={{
          className: "",
          style: {
            // position: "absolute",
            right: "0 !important",
            bottom: "100px !important",
            border: "1px solid #713200",
            // marginBottom: 50,
            // paddingBottom: 200,
            padding: "16px",
            color: "#713200",
          },
        }}
      />
      <Cart />
      <Navbar />
      <CareModal isOpen={isCareOpen} setIsOpen={toggleCare} />
      <AnimatePresence>
        <div className="min-h-full md:!min-w-[100%] flex max-sm:flex-col md:justify-start md:items-center md:gap-6 gap-4 relative overflow-hidden">
          <div className="aboutshow md:w-[80%] h-[100%] max-sm:h-[350px] min-h-[270px] rounded-[49px] bg-black relative overflow-hidden border-2 border-primary-red ">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
              className="w-full h-full bg-black"
            >
              <video
                className="video "
                autoPlay
                loop
                muted
                playsInline
                preload="auto"
              >
                <source src={aboutVid} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </motion.div>
          </div>
          <div
            className={
              isMobile
                ? " text-[17px] text-center text-primary-red border-2 border-primary-red rounded-[49px] p-4 py-8 px-7 "
                : "max-w-[25%]  text-[17px] text-center text-primary-red border-2 h-full rounded-[49px] border-primary-red items-center justify-center flex px-7"
            }
          >
            <p className="">
              Orima reimagines the hair comb, a universal and democratic design icon. Combs are an important element of milions of people's daily routines and the subtlety connecting objects with design is at the center of the practice to breathe new life into a tool that has been around for centuries. <br className="mb-4"/>
              The driving principle behind Orima is to give new meaning to common objects through a soft, futuristic, and minimal approach.
            </p>
          </div>
        </div>
        <div className="md:min-w-[65%] min-h-full max-sm:h-[350px] rounded-[49px] bg-black relative overflow-hidden border-2 border-primary-red ">
          <motion.div
            className="slider-background"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            style={{ backgroundImage: `url(${mrsMelissa})` }}
          />
        </div>
        {true ? (
          <div className="md:min-w-[25%] min-h-[150px] flex justify-center items-center md:text-2xl text-xl font-bold text-primary-red rounded-[49px] relative overflow-hidden border-2 border-primary-red ">
            <h4>Comb home.</h4>
          </div>
        ) : null}
        <div className="md:min-w-[65%] min-h-full max-sm:h-[350px] rounded-[49px] bg-black relative overflow-hidden border-2 border-primary-red ">
          <motion.div
            className="slider-background"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            style={{ backgroundImage: `url(${mrAlex})` }}
          />
        </div>
        <div
          className={
            "min-w-[28%] font-bold text-center text-primary-red border-2 border-primary-red rounded-[49px] flex flex-col justify-around md:gap-5 max-sm:gap-4 max-sm:py-4 px-4 "
          }
        >
          <h3 className="lg:text-xl md:text-lg sm:text-[5px] font-light text-center ">Orima Combs are Handmade to Last</h3>
          <div>
            <h4 className="lg:text-xl md:text-sm max-sm:text-md mb-1">
              Strong and Durable
            </h4>
            <p className="lg:text-xl md:text-lg sm:text-[5px] font-light text-center ">
              Crafted from cellulose acetate; a strong, durable material that resists wear over time, they're built to endure.
            </p>
          </div>
          <div>
            <h4 className="lg:text-xl md:text-sm max-sm:text-md mb-1">
              Hypoallergenic and Renewable
            </h4>
            <p className="lg:text-xl md:text-lg sm:text-[5px] font-light text-center ">
              Hypoallergenic and renewable, cellulose acetate offers a natural feel and is derived from plant sources, making it gentle on sensitive skin.
            </p>
          </div>
          <div>
            <h4 className="lg:text-xl md:text-sm max-sm:text-md mb-1">
              A Comb for Life
            </h4>
            <p className="lg:text-xl md:text-lg sm:text-[5px] font-light text-center ">
              An Orima comb is a lifelong tool, crafted from premium cellulose acetate that feels as good as it looks.
            </p>
          </div>
          <div className="w-full flex justify-center">
            <button
              onClick={() => toggleCare()}
              className="round-white-button max-w-[200px]"
            >
              Care
            </button>
          </div>
        </div>
        <div className="overflow-hidden z-[1]">
          {currentPath == "about" ? <Footer /> : null}
        </div>
      </AnimatePresence>
    </section>
  );
};

export default About;
