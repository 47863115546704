import React from "react";
import CombModel from "../components/CombModel";
import { homeVid } from "../constants/palette";

import "./Video.css";
import { AppStore } from "../constants/Stores";
import { useLocation, useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const isMobile = AppStore((state) => state.isMobile);
  const location = useLocation();
  const currentPath = location.pathname;
  const addCartCount = AppStore((state) => state.addCartCount);
  const decreaseCartCount = AppStore((state) => state.decreaseCartCount);

  return (
    <section
      className="w-full h-[calc(100vh_-_80px)] flex max-sm:flex-col gap-4 p-3 relative max-sm:mt-4 !z-0"
      id="landing"
    >
      <div className="sidebar min-w-[435px] max-sm:w-full max-sm:min-w-full rounded-[49px] max-sm:min-h-[63%] flex flex-col items-center justify-between py-5 border-2 border-primary-red z-[10]">
        <div className="sidebar_title h-[8%] flex items-center">
          <h3 className="text-primary-red font-bold text-2xl">BEAN COMB</h3>
        </div>
        <div className="sidebar_model h-[60%] pb-6 max-sm:pb-10 flex">
          <CombModel />
        </div>
        <div className="sidebar_operations w-full h-[10%] flex flex-col gap-3 justify-end pb-2 items-center md:z-[71]">
          <div className="set_quantity round-white-button ">$40</div>
          <div
            className="set_quantity round-red-button hover:cursor-pointer"
            onClick={() => addCartCount()}
          >
            ADD TO CART
          </div>
        </div>
      </div>
      <div className="presentation w-full h-full rounded-[49px] border-2 border-primary-red overflow-hidden bg-black">
        <div className="video-container">
          {/* <iframe
            src="https://drive.google.com/file/d/10RzVOWIIKcKiDL3n9o_O3lFuLaxpYslO/preview"
            width="640"
            height="480"
            allow="autoplay"
            allowFullScreen={false}
            title="home"
            className="video"
            // play
          ></iframe> */}
          <video className="video" autoPlay loop muted playsInline>
            <source src={homeVid} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {isMobile ? (
            <div
              className="absolute w-screen bottom-14 max-sm:bottom-7 left-0 right-0 flex justify-center"
              onClick={() => navigate("/about")}
            >
              <button className="round-red-button ml-[-3.5%] max-w-[110px] max-h-[40px]">
                About
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default Home;
