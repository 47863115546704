// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --green: #c62625;
  --gray: #bbbbbb;
  --white: #fff;
}

.subscribe-button {
  position: relative;
  outline: none;
  height: 40px;
  width: 100px;
  border-radius: 40px;
  background: var(--white);
  border: 2px solid var(--green);
  color: var(--green);
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.25s ease;
  text-align: center;
  letter-spacing: 1px;
}

.subscribe-button.loading {
  width: 40px;
  border-width: 3px;
  border-color: var(--gray);
  font-size: 0;
  border-left-color: var(--green);
  animation: rotating 2s 0.25s linear infinite;
}

.subscribe-button.success {
  width: 40px;
  font-size: 13px;
  color: var(--white);
  background: var(--green);
}

/* .subscribe-button:after {
  content: "Submit";
} */

#footer-btns {
  z-index: 1000;
}

.subscribe-button.loading:after {
  content: "";
}

.subscribe-button.success:after {
  font-family: "FontAwesome";
  content: "\\f00c"; /* Checkmark symbol */
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,wBAAwB;EACxB,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,0BAA0B;EAC1B,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,yBAAyB;EACzB,YAAY;EACZ,+BAA+B;EAC/B,4CAA4C;AAC9C;;AAEA;EACE,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,wBAAwB;AAC1B;;AAEA;;GAEG;;AAEH;EACE,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,0BAA0B;EAC1B,gBAAgB,EAAE,qBAAqB;AACzC;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[":root {\n  --green: #c62625;\n  --gray: #bbbbbb;\n  --white: #fff;\n}\n\n.subscribe-button {\n  position: relative;\n  outline: none;\n  height: 40px;\n  width: 100px;\n  border-radius: 40px;\n  background: var(--white);\n  border: 2px solid var(--green);\n  color: var(--green);\n  font-size: 12px;\n  font-weight: bold;\n  cursor: pointer;\n  transition: all 0.25s ease;\n  text-align: center;\n  letter-spacing: 1px;\n}\n\n.subscribe-button.loading {\n  width: 40px;\n  border-width: 3px;\n  border-color: var(--gray);\n  font-size: 0;\n  border-left-color: var(--green);\n  animation: rotating 2s 0.25s linear infinite;\n}\n\n.subscribe-button.success {\n  width: 40px;\n  font-size: 13px;\n  color: var(--white);\n  background: var(--green);\n}\n\n/* .subscribe-button:after {\n  content: \"Submit\";\n} */\n\n#footer-btns {\n  z-index: 1000;\n}\n\n.subscribe-button.loading:after {\n  content: \"\";\n}\n\n.subscribe-button.success:after {\n  font-family: \"FontAwesome\";\n  content: \"\\f00c\"; /* Checkmark symbol */\n}\n\n@keyframes rotating {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
