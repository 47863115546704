import Navbar from "./components/Navbar";
import Layout from "./pages/Layout";
import { AppStore } from "./constants/Stores";
import { Toaster } from "react-hot-toast";

function App() {
  const isMobile = AppStore((state) => state.isMobile);
  const isMenuOpen = AppStore((state) => state.isMenuOpen);

  const metaThemeColor = document.querySelector('meta[name="theme-color"]');
  metaThemeColor.setAttribute("content", "#ffffff");
  return (
    <main className="">
      {/* <div
        className={
          !isMobile
            ? `bg-transparent absolute float-right bottom-[100px] right-0 w-[0px] h-screen z-[100] !overflow-hidden pb-[100px] `
            : ""
        }
      > */}
      <Toaster
        containerStyle={{
          top: 0,
          right: 0,
        }}
        containerClassName="toast-notif"
        toastOptions={{
          className: "",
          style: {
            // position: "absolute",
            right: "0 !important",
            bottom: "100px !important",
            border: "1px solid #713200",
            // marginBottom: 50,
            // paddingBottom: 200,
            padding: "16px",
            color: "#713200",
          },
        }}
      />
      {/* </div> */}
      <Navbar />
      <Layout />
    </main>
  );
}

export default App;
